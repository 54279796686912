html {
  height: 100%;
}
body {
  padding: 0;
  margin: 0;
  height: 100%;
}
#root {
  height: 100%;
}
.height-100p100 {
  height: 100%;
}

.root-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.root-wrapper .main-wrapper {
  flex: 1;
}

#modal-root {
  position: relative;
  z-index: 999;
}

.modal {
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fab-wrapper {
  position: absolute;
  bottom: 24px;
  right: 24px;
  width: 50px;
  height: 50px;
}

.fab-wrapper .fab-button {
  background-color: #337ab7;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 20px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px;
  z-index: 998;
  transition: background-color 0.2s;
}
.fab-button:hover {
  background-color: #4f93ce;
  transform: rotate(360deg);
  transition: transform 0.2s;
}
.fab-button:focus {
  outline: none;
}

.Highlight {
  background-color: rgb(188, 185, 236);
}
